import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lesson"
export default class extends Controller {
  connect() {
  }

  async getWombs() {
    let res = await fetch(`/admin/wombs`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    return JSON.parse(await res.text());
  }

  async getComponents(e) {
    const groupWombs = e.target.closest('.group-wombs')
    const lessonWombId = groupWombs.querySelector("#lesson_womb_id").value

    let res = await fetch(`/admin/wombs/${lessonWombId}/components`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    const components = JSON.parse(await res.text());

    const areaComponent = groupWombs.querySelector("#area-component")
    areaComponent.innerHTML = " "

    const selectElement = this.createSelect(components, "change->lesson#getLectures", "lesson_component_id", "lesson[lesson_womb_component_lectures_attributes][][component_id]")
    areaComponent.appendChild(selectElement);
  }

  async getLectures(e) {
    const groupWombs = e.target.closest('.group-wombs')
    const lessonWombId = groupWombs.querySelector("#lesson_womb_id").value
    const lessonComponentId = groupWombs.querySelector("#lesson_component_id").value

    let res = await fetch(`/admin/wombs/${lessonWombId}/components/${lessonComponentId}/lectures`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    const lectures = JSON.parse(await res.text());

    const areaLecture = groupWombs.querySelector("#area-lecture")
    areaLecture.innerHTML = " "

    const selectElement = this.createSelect(lectures, "change->lesson#getLectureVideos", "lesson_lecture_id", "lesson[lesson_womb_component_lectures_attributes][][lecture_id]")
    areaLecture.appendChild(selectElement);
  }

  async getLectureVideos(e) {
    const groupWombs = e.target.closest('.group-wombs')
    const lessonWombId = groupWombs.querySelector("#lesson_womb_id").value
    const lessonComponentId = groupWombs.querySelector("#lesson_component_id").value
    const lessonLectureId = groupWombs.querySelector("#lesson_lecture_id").value

    let res = await fetch(`/admin/wombs/${lessonWombId}/components/${lessonComponentId}/lectures/${lessonLectureId}/lecture_videos`, {
      method: 'GET',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    })

    const lectureVideos = JSON.parse(await res.text());

    const areaLectureVideo = groupWombs.querySelector("#area-lecture-video")
    areaLectureVideo.innerHTML = " "

    if (lectureVideos.length == 0) {
      const strongElement = document.createElement("strong");
      strongElement.className = "ms-2";
      strongElement.textContent = "Essa aula não possui videos";
      areaLectureVideo.appendChild(strongElement);
      return
    }

    const divRowElement = document.createElement("div");
    divRowElement.className = "row";

    lectureVideos.forEach(lectureVideo => {
      const divColElement = this.createCheckbox(lectureVideo)
      divRowElement.appendChild(divColElement)
    });

    areaLectureVideo.appendChild(divRowElement);
  }

  createCheckbox(lectureVideo) {
    const divColElement = document.createElement("div");
    divColElement.className = "col-md-12";

    const inputElement = document.createElement("input");
    inputElement.className = "form-check-input";
    inputElement.type = "checkbox";
    inputElement.value = lectureVideo.id;
    inputElement.name = "lesson[lesson_lecture_videos_attributes][][lecture_video_id]";
    inputElement.id = `lecture_video_${lectureVideo.id}`;

    const labelElement = document.createElement("label");
    labelElement.className = "form-check-label";
    labelElement.textContent = lectureVideo.title;
    labelElement.setAttribute("for", `lecture_video_${lectureVideo.id}`)

    divColElement.appendChild(inputElement)
    divColElement.appendChild(labelElement)

    return divColElement
  }

  createSelect(options, action, id, name) {
    const selectElement = document.createElement("select");
    selectElement.id = id;
    selectElement.className = "form-control";
    selectElement.name = name;
    selectElement.setAttribute("data-action", action);

    const optionElement = document.createElement("option");
    selectElement.appendChild(optionElement);

    options.forEach(option => {
      const optionElement = document.createElement("option");
      optionElement.value = option.id;
      optionElement.textContent = option.title;
      selectElement.appendChild(optionElement);
    });

    return selectElement
  }

  async newWombs(e) {
    e.preventDefault();

    const wombs = await this.getWombs()

    let options = '<option value="" label=" "></option>';
    wombs.forEach(womb => {
      options += `<option value="${womb.id}">${womb.title}</option>`;
    });

    $('.global-wombs').append(`
      <div class="group-wombs mb-3">
        <div class="d-flex justify-content-end">
          <button class="btn btn-danger px-2" data-action="lesson#removeGroupWombs">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="lesson_womb_id">Matriz</label>
              <select class="form-control" id="lesson_womb_id" data-action="lesson#getComponents" name="lesson[lesson_womb_component_lectures_attributes][][womb_id]">
                ${options}
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="lesson_component_id">Módulo</label>
              <div id="area-component">
                <select class="form-control" id="lesson_component_id" name="lesson[lesson_womb_component_lectures_attributes][][component_id]"><option selected="selected" value="">Selecione uma matriz</option></select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label for="lesson_lecture_id">Aula</label>
              <div id="area-lecture">
                <select class="form-control" id="lesson_lecture_id" name="lesson[lesson_womb_component_lectures_attributes][][lecture_id]"><option selected="selected" value="">Selecione um módulo</option></select>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-control-label" for="lesson_Videos">Videos</label>
              <div id="area-lecture-video">
                <strong class="ms-2">Selecione uma aula</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    `)

    this.changePositionWombs()
  }

  removeGroupWombs(e) {
    e.preventDefault();

    const groupWombs = e.target.closest('.group-wombs');
    if (groupWombs) {
      groupWombs.remove();
    }

    this.changePositionWombs()
  }

  changePositionWombs() {
    const globalWombs = document.querySelector('.global-wombs');
    const childDivs = globalWombs.children;

    Array.from(childDivs).forEach((div, index) => {
      div.id = `womb-${index + 1}`;
    });
  }
}
